import { StyledLoaderWholePage } from "./LoaderWholePage.styles"
import React from "react"

const LoaderWholePage = () => (
    <StyledLoaderWholePage>
        <div className="loader" />
    </StyledLoaderWholePage>
)

export default React.memo(LoaderWholePage)
