import styled from "styled-components"
import { StyledLoader } from "../Loader/Loader.styles"

export const StyledLoaderWholePage = styled(StyledLoader)`
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 500;
`
